import React, {useEffect} from 'react';
import Sidebar from "../components/Layout/Navigation/Sidebar/Sidebar";
import {Helmet} from "react-helmet";
import Footer from "../components/Layout/Footer";
import Navigation from "../components/Layout/Navigation/Navigation";
import '../../translation';
import { useTranslation } from 'react-i18next';
import Piano from './../assets/images/activies-culturelles/piano.jpg';
import Violon from './../assets/images/activies-culturelles/violon.jpg'
import Echec from './../assets/images/activies-culturelles/echec.jpg'


const ActivitesCulturelles = () => {
    const { t } = useTranslation('activitesCulturelles');
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])

    return (
        <>
            <Navigation />
            <div className='container-custom page d-flex'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Activités culturelles| Collège Lycée Lafayette Nice</title>
                    <meta name="description" content="Le Collège Lycée Lafayette offre cursus bilingue et pluridisciplinaire : éducation bi-culturelle, une pédagogie différenciée, la confiance et l'estime de soi." />
                </Helmet>
                <Sidebar/>
                <div className='content fadeInEarth'>
                    <div className='special-title'>{t('t1')}</div>
                    <p className='text text-center'>{t('t2')}</p><br/>
                    <h4 className='content-title text-left'>{t('t3')}</h4>
                    <p className='text'>{t('t4')}</p><br/>
                    <p className='text'>{t('t5')}</p>
                    <div className='page-divider'></div>
                    <div className='row align-items-center'>
                        <div className='col-12 col-md-4'>
                            <strong>{t('t6')}</strong><br/><br/>
                            <img src={Piano} alt='piano' width='100%' />
                        </div>
                        <div className='col-12 col-md-4'>
                            <strong>{t('t7')}</strong><br/><br/>
                            <img src={Echec} alt='violon' width='100%' />
                        </div>
                        <div className='col-12 col-md-4'>
                            <strong>{t('t8')}</strong><br/><br/>
                            <img src={Violon} alt='echec' width='100%' />
                        </div>
                    </div><br/>
                    <p className='text' dangerouslySetInnerHTML={{__html: t('t9')}}></p>

                </div>
            </div>
            <Footer />
        </>

    )
}

export default ActivitesCulturelles;